import axios from 'axios'
import Cookies from 'js-cookie'
import { Message } from 'ant-design-vue'
// import { apiHostConfig } from './config'

// const apiHost = 'http://rocketmq.console.devcloud.test.chinawayltd.com/';
// const apiHost = 'http://172.22.111.233:8094/';
// const apiHost = apiHostConfig[window.location.host].host
// const apiHost = apiHostConfig[window.location.host] ? apiHostConfig[window.location.host].loginHost : apiHostConfig.dev.host

export default {
  install (Vue) {
    const fetch = axios.create({
      baseURL: '/',
      headers: { 'Content-Type': 'application/json' }
    })

    fetch.interceptors.request.use((config) => {
      const token = Cookies.get('token')
      token && (config.headers.token = Cookies.get('token'))
      return config
    }, error => Promise.reject(error))

    fetch.interceptors.response.use((response) => {
      console.log('response', response)
      if (response.data.code !== 200 && !/ip/.test(response.request.responseURL)) {
        Message.error(response.data.msg ? response.data.msg : '接口错误')
        if (response.data.msg === '请登录!') {
          Vue.$pub.login()
        }
        return Promise.reject(response.data)
      }
      if (response.data.code === 500 && /ip/.test(response.request.responseURL)) {
        // ip登录 不报错
        return response.data
      }
      return response.data
    }, error => {
      console.log(error.response, 'error.response')
      // 未登录
      if (error.response.status === 401) {
        Vue.$pub.login()
        return
      }
      Message.error('网络错误')
      return Promise.reject(error)
    })

    window.$http = {
      post (path, data = {}) {
        return fetch({
          method: 'post',
          url: path,
          data
        })
      },
      get (path, params = {}) {
        return fetch({
          method: 'get',
          url: path,
          params
        })
      },
      put (path, data = {}) {
        return fetch({
          method: 'put',
          url: path,
          data
        })
      },
      delete (path, data = {}) {
        return fetch({
          method: 'delete',
          url: path,
          data
        })
      }
    }
  }
}
