import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'js-cookie'
import { Modal } from 'ant-design-vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/HomeView.vue'),
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "HomePage" */ '../views/HomePage.vue'),
        meta: {
          title: '首页',
          needLogin: false,
          headerTabsActive: 'Home'
        }
      },
      {
        path: 'search',
        name: 'Search',
        component: () => import(/* webpackChunkName: "Search" */ '../views/SearchPage.vue'),
        meta: {
          title: '搜索',
          needLogin: false,
          headerTabsActive: 'Search'
        }
      },
      {
        path: 'fullSearch',
        name: 'FullSearch',
        component: () => import(/* webpackChunkName: "Search" */ '../views/SearchFullPage.vue'),
        meta: {
          title: '全文搜索',
          needLogin: false,
          headerTabsActive: 'Search'
        }
      },
      {
        path: 'search/:type',
        name: 'Search',
        component: () => import(/* webpackChunkName: "Search" */ '../views/SearchPage.vue'),
        meta: {
          title: '搜索',
          needLogin: false,
          headerTabsActive: 'Search'
        }
      },
      {
        path: 'navigation/:type',
        name: 'Navigation',
        component: () => import(/* webpackChunkName: "Navigation" */ '../views/NavigationPage.vue'),
        meta: {
          title: '文献导航',
          needLogin: false,
          headerTabsActive: 'Navigation'
        }
      },
      {
        path: 'papers',
        name: 'Papers',
        component: () => import(/* webpackChunkName: "Papers" */ '../views/PapersPage.vue'),
        meta: {
          title: '报刊',
          needLogin: false,
          headerTabsActive: 'Navigation'
        }
      },
      {
        path: 'papers/:id',
        name: 'PapersDetail',
        component: () => import(/* webpackChunkName: "Papers" */ '../views/PapersDetailPage.vue'),
        meta: {
          title: '报刊详情',
          needLogin: false,
          headerTabsActive: 'Navigation'
        }
      },
      {
        path: 'contact',
        name: 'contact',
        component: () => import(/* webpackChunkName: "Contact" */ '../views/ContactUs.vue'),
        meta: {
          title: '联络我们',
          needLogin: false,
          headerTabsActive: 'AboutUs'
        }
      },
      {
        path: 'about',
        name: 'AboutUs',
        component: () => import(/* webpackChunkName: "AboutUs" */ '../views/AboutUs.vue'),
        meta: {
          title: '关于我们',
          needLogin: false,
          headerTabsActive: 'AboutUs'
        }
      },
      {
        path: 'history',
        name: 'History',
        component: () => import(/* webpackChunkName: "History" */ '../views/HistoryPage.vue'),
        meta: {
          title: '浏览历史',
          needLogin: true,
          headerTabsActive: 'History'
        }
      }
    ]
  },
  {
    path: '/user',
    name: 'User',
    component: () => import(/* webpackChunkName: "Home" */ '../views/UserView.vue'),
    redirect: '/user/login',
    children: [
      {
        path: 'userAgreement',
        name: 'UserAgreement',
        component: () => import(/* webpackChunkName: "UserAgreement" */ '../views/UserDealPage.vue'),
        meta: {
          title: '用户协议',
          needLogin: false,
          headerTabsActive: 'User'
        }
      },
      {
        path: 'privacyPolicy',
        name: 'PrivacyPolicy',
        component: () => import(/* webpackChunkName: "PrivacyPolicy" */ '../views/PrivacyPolicyPage.vue'),
        meta: {
          title: '隐私政策',
          needLogin: false,
          headerTabsActive: 'User'
        }
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "Login" */ '../views/LoginPage.vue'),
        meta: {
          title: '登录',
          needLogin: false,
          headerTabsActive: 'User'
        }
      },
      {
        path: 'forget',
        name: 'Forget',
        component: () => import(/* webpackChunkName: "Login" */ '../views/ForgetPage.vue'),
        meta: {
          title: '忘记密码',
          needLogin: false,
          headerTabsActive: 'User'
        }
      },
      {
        path: 'changePwd',
        name: 'ChangePwd',
        component: () => import(/* webpackChunkName: "Login" */ '../views/ChangePwd.vue'),
        meta: {
          title: '修改密码',
          needLogin: true,
          headerTabsActive: 'User'
        }
      },
      {
        path: 'signIn',
        name: 'SignIn',
        component: () => import(/* webpackChunkName: "Login" */ '../views/SignInPage.vue'),
        meta: {
          title: '注册',
          needLogin: false,
          headerTabsActive: 'User'
        }
      }
    ]
  },
  {
    path: '/viewer/word/:id',
    name: 'ViewerWord',
    component: () => import(/* webpackChunkName: "ViewerWord" */ '../views/WordViewer.vue'),
    meta: {
      title: '阅读模式',
      needLogin: true
    }
  },
  {
    path: '/viewer/pdf/:id',
    name: 'ViewerPdf',
    component: () => import(/* webpackChunkName: "ViewerPdf" */ '../views/PdfViewer.vue'),
    meta: {
      title: '阅读模式',
      needLogin: true
    }
  },
  {
    path: '/viewer/img/:id',
    name: 'ViewerImg',
    component: () => import(/* webpackChunkName: "ViewerImg" */ '../views/ImgViewer.vue'),
    meta: {
      title: '阅读模式',
      needLogin: true
    }
  },
  {
    path: '/viewer/txt/:id',
    name: 'TxtViewer',
    component: () => import(/* webpackChunkName: "TxtViewer" */ '../views/TxtViewer.vue'),
    meta: {
      title: '阅读模式',
      needLogin: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const confirm = () => {
  Modal.info({
    centered: true,
    title: '登录失效，请先登录。',
    okText: '去登录',
    onOk () {
      console.log('OK')
      Vue.$pub.login()
    },
    onCancel () {
      console.log('Cancel')
    }
  })
}

router.beforeEach((to, from, next) => {
  console.log('beforeEach', to)

  const token = Cookies.get('token')
  if (token) {
    // window.$http.post('/biz/login/getUserInfo').then(r => {
    //   console.log(r)
    // })
    // 路由跳转后，让页面回到顶部
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    window.pageYOffset = 0
    next()
  } else {
    window.$http.post('/biz/login/ip').then(r => {
      console.log(r)
      if (r?.data?.token) {
        // 白名单登录成功
        Cookies.set('userInfo', JSON.stringify(r.data))
        Cookies.set('userName', r.data.username || r.data.nickName)
        Cookies.set('token', r.data.token)
        Cookies.set('uuid', r.data.uuid)
        Cookies.set('loginType', 'ip')
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
        window.pageYOffset = 0
        next()
      } else {
        if (to.meta.needLogin) {
          // Message.error('查看详情，请先登录')
          confirm()
          // setTimeout(() => {
          //   Vue.$pub.login()
          // }, 2000)
        } else {
          document.body.scrollTop = 0
          document.documentElement.scrollTop = 0
          window.pageYOffset = 0
          next()
        }
      }
    }).catch(() => {
      if (to.meta.needLogin && !token) {
        // Message.error('查看详情，请先登录')
        confirm()
        // setTimeout(() => {
        //   Vue.$pub.login()
        // }, 2000)
      }
    })
  }
})

export default router
