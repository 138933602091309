import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import http from './assets/js/http'
import pub from './assets/js/pub'
import { isMobile } from './assets/js/utils'
import './assets/css/base.less'
import { message } from 'ant-design-vue'
// 对vant进行样式覆盖
import './assets/css/newVant.less'
import hevueImgPreview from 'hevue-img-preview'
import { version } from '../package.json'
Vue.use(hevueImgPreview, {
  keyboard: true,
  clickMaskCLose: true
})

const useList = [http, pub]
useList.forEach(item => {
  Vue.use(item)
})

Vue.config.productionTip = false

// 全局挂载message
Vue.prototype.$message = message
// Vue.prototype.$confirm = modal
Vue.prototype.$isMobile = isMobile()

console.log('version', version)

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')

// 阻止ios缩放
document.addEventListener('gesturestart', function (event) {
  event.preventDefault()
})
// const size = function () {
//   const docEl = document.documentElement
//   const resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize'
//   const recalc = function () {
//     const clientWidth = docEl.clientWidth
//     if (!clientWidth) return
//     /* 设置一个屏幕最大宽度临界值，当大于这个宽度时，字体也不放大了 */
//     if (clientWidth >= 1240) {
//       docEl.style.fontSize = '14px'
//     } else {
//       /* 1920是设计稿的宽度，14是浏览器默认值14(px) */
//       docEl.style.fontSize = 14 * (clientWidth / 1920) + 'px'
//     }
//   }

//   if (!document.addEventListener) return
//   window.addEventListener(resizeEvt, recalc, false)
//   document.addEventListener('DOMContentLoaded', recalc, false)
// }
// size()

// setTimeout(() => {
//   window.addEventListener('resize', computeRootFontSize, false)
//   computeRootFontSize()
// }, 0)
