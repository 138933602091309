import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    typeEnum: null
  },
  getters: {
  },
  mutations: {
    setTypeEnum (state, value) {
      state.typeEnum = value
    }
  },
  actions: {
    getTypeEnum ({ commit }) {
      const typeEnum = {}
      window.$http.get('/biz/contentTypeConf/getListByParentCode', { parentType: '0' }).then(r => {
        r.data.forEach(item => {
          typeEnum[item.type] = item.name
        })
        commit('setTypeEnum', typeEnum)
      }).catch(e => {
        console.log(e)
      })
    }
  },
  modules: {
  }
})
