// 应用内可用的公共函数
import Cookies from 'js-cookie'
import store from '../../store'

export default {
  install (Vue) {
    Vue.$pub = Vue.prototype.$pub = {
      login () {
        Cookies.remove('userInfo')
        Cookies.remove('userName')
        Cookies.remove('token')
        Cookies.remove('uuid')
        Cookies.remove('loginType')
        location.href = `/user/login?redirect=${encodeURIComponent(location.href)}`
      },
      logout () {
        console.log('logout')
        Cookies.remove('userInfo')
        Cookies.remove('userName')
        Cookies.remove('token')
        Cookies.remove('uuid')
        Cookies.remove('loginType')
        location.href = '/user/login'
      },
      loadingShow () {
        store.commit('updateSpinning', true)
      },
      loadingHide () {
        store.commit('updateSpinning', false)
      }
    }
  }
}
