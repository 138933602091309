<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="less">
#app {
  font-family: Libian;
  color: #2c3e50;
}
</style>

<script>
export default {
  mounted () {
    const typeEnum = this.$store.state.typeEnum
    if (!typeEnum) {
      this.$store.dispatch('getTypeEnum')
    }
  }
}
</script>
